<template lang="pug">
  div.row
    div.col-12
      button.btn.btn-outline-danger(type="button", @click="calc") 使用状況を再計算する
    div.col-12.mt-3
      VueGoodTable.users(:key="reset_count", :columns="columns", :rows="rows", @on-row-click="go_user", :pagination-options="pagination_options", styleClass="vgt-table striped condensed", :sort-options="sort_options", @on-column-filter="onColumnFilter", @on-sort-change="onSortChange")
        //- Header
        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'c'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/></svg>
          span(v-else-if="props.column.field == 'card'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16"><path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/><path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/></svg>
          span(v-else) {{ props.column.label }}
        
        //- Row
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'p'", :class="props.row.cancel? 'text-decoration-line-through':''")
            | {{ props.row.ss==1? "(Trial)":"" }}{{ props.row.ss==6? "(M)":"" }}{{ props.row.ss==7? "(S)":"" }}{{ props.row.p!=0? plans[props.row.p]:props.row.project }}
          span(v-else-if="props.column.field == 'c'", :class="props.row.cancel? 'text-decoration-line-through':''")
            | {{ props.row.c===1? "M":"Y" }}
          span(v-else-if="props.column.field == 'card'")
            <svg v-if="props.row.card" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back" viewBox="0 0 16 16"><path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/><path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/></svg>
          span(v-else-if="props.column.field == 'id'")
            router-link(:to="{ path:'user', query:{ id:props.row.id } }") {{ props.row.id }}
          span(v-else-if="props.column.field == 'a'") {{ props.row.a.toLocaleString() }}
          span(v-else, :class="props.row.cancel? 'text-decoration-line-through':''")
            | {{ props.formattedRow[props.column.field] }}

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
  name:"Users",
  components:{
    VueGoodTable
  },
  watch: {
    $route(to, from) {
      if (Object.keys(to.query).length===0){
        this.sort_options.initialSortBy = { field:'id', type:'asc'};
        this.parse_query();
        this.reset_count++;
      }
    }
  },
  data (){
    return {
      reset_count:0,
      pagination_options: {
        enabled: true,
        perPage: 100,
        perPageDropdown: [25,50,100,200]
      },
      sort_options:{ 
        enabled:true, 
        initialSortBy:{ field:'id', type:'desc'}
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Name',
          field: 'n',
          filterOptions: { enabled: true },
        },
        {
          label: 'Email',
          field: 'm',
          filterOptions: { enabled: true },
        },
        {
          label: 'card',
          field: 'card',
          width: '50px',
          sortable: false,
        },
        {
          label: 'Plan',
          field: 'p',
          width: '150px',
          filterOptions: { enabled: true, filterDropdownItems: [{ value:0, text:"Project"},{ value:1, text:"Cancel"},{ value:2, text:"Customize"},{ value:3, text:"Basic5"},{ value:4, text:"Basic5_1min"},{ value:5, text:"Basic25"},{ value:6, text:"Basic25_1min_5"},{ value:7, text:"Basic25_1min_10"},{ value:8, text:"Basic25_1min_15"},{ value:9, text:"Basic25_1min_20"},{ value:10, text:"Basic25_1min_25"},{ value:11, text:"Advanced"}] },
        },
        {
          label: 'Cycle',
          field: 'c',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true, filterDropdownItems: [ { value: 1, text: 'Month' }, { value: 2, text: 'Year' } ] },
        },
        {
          label: 'Expiry',
          field: 'e',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'yyyy/LL/dd HH:mm',
        },
        {
          label: '料金',
          field: 'a',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          label: '1min',
          field: '_1',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: '10min',
          field: '_10',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: '60min',
          field: '_60',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'SS',
          field: 's',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Proxy',
          field: 'proxy',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      rows: [],
      query_parsed:false,
      plans:[ "Project", "Cancel", "Customize", "Basic5", "Basic5_1min", "Basic25", "Basic25_1min_5", "Basic25_1min_10", "Basic25_1min_15", "Basic25_1min_20", "Basic25_1min_25", "Advanced"],
      cycles:["","(月)","(年)"]
    }
  },
  computed :{
  },
  created (){
    this.parse_query();
  },
  async mounted (){
    await this.get();
  },
  methods: {
    parse_query (){
      if ("id" in this.$route.query)
        this.columns[0].filterOptions.filterValue = this.$route.query.id;
      if ("n" in this.$route.query)
        this.columns[1].filterOptions.filterValue = this.$route.query.n;
      if ("m" in this.$route.query)
        this.columns[2].filterOptions.filterValue = this.$route.query.m;
      if ("c" in this.$route.query)
        this.columns[3].filterOptions.filterValue = this.$route.query.c;
      if ("p" in this.$route.query)
        this.columns[4].filterOptions.filterValue = this.$route.query.p;
      if ("t" in this.$route.query)
        this.columns[7].filterOptions.filterValue = this.$route.query.t;
      if ("sort" in this.$route.query){
        if(typeof(this.$route.query.sort)=="string")
          this.sort_options.initialSortBy = [ { field:this.$route.query.sort.split("_")[0], type:this.$route.query.sort.split("_")[1] } ]
        else
          this.sort_options.initialSortBy = this.$route.query.sort.reduce((r,e)=>{ r.push({ field:e.split("_")[0], type:e.split("_")[1] }); return r; }, []);
      }
    },
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get (){
      this.loading();
      let res = null;
      res = await this.axios.get("/api/v2/users");
      this.rows = res.data;
      this.query_parsed = true;
      this.$swal.close();
    },
    async calc(){
      this.loading();
      try{
        await this.axios.get("/api/calc_user_num");
        await this.get();
      }
      catch(err){
        this.$swal("Error", "", "error");
        console.log(err);
      }
    },
    go_user(e){
      this.$router.push({ path:"user", query:{id:e.row.id} });
    },
    onColumnFilter (params){
      if (this.query_parsed){
        let f = {};
        if(this.$route.query.sort)
          f.sort = this.$route.query.sort;
        for (let [key, value] of Object.entries(params.columnFilters)) {
          if (value)
            f[key] = value;
        }
        this.$router.push({ query:f });
      }
    },
    onSortChange (params){
      if (this.query_parsed)
        this.$router.push({ query:{ ...this.$route.query, sort:params.reduce((r,e)=>{ r.push(`${e.field}_${e.type}`); return r; }, []) } });
    }
  }
}
</script>

<style lang="scss" scoped>
.users ::v-deep {
  .feather {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-top: 1px;
  }
  
  .vgt-table {
    thead th {
      padding-right: .75em;
    }
    tr:hover{
      background-color: #bfe2e9!important;
    }
  }
  

}
</style>